import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { H3Toast, H3Modal } from '@h3/thinking-ui';
import tabBar from './tabBar.vue';
Vue.use(H3Toast);
Vue.use(H3Modal);
let mWorkPlatform = class mWorkPlatform extends Vue {
    get showTabBar() {
        return this.$route.meta && this.$route.meta.showTabBar;
    }
};
mWorkPlatform = __decorate([
    Component({
        name: 'mWorkPlatform',
        components: {
            tabBar,
        },
    })
], mWorkPlatform);
export default mWorkPlatform;
