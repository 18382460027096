import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
let ComponentName = class ComponentName extends Vue {
    // 钉钉登录则不展示设置tab
    get isFreeLogin() {
        return sessionStorage.IS_DINGTALK || sessionStorage.IS_WECHAT;
    }
};
ComponentName = __decorate([
    Component({
        name: 'tabBar',
        components: {},
    })
], ComponentName);
export default ComponentName;
